<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="tabClick" class="text_table">
      <el-tab-pane label="周训练计划" name="1" v-if="!$store.getters.permissionsName('周训练计划')"></el-tab-pane>
      <el-tab-pane label="阶段训练计划" name="2" v-if="!$store.getters.permissionsName('阶段训练计划')"></el-tab-pane>
    </el-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: '0'
    }
  },
watch: {
  $route(a) {
  }
},
  mounted() {
    
    if(this.$route.query.type) {
      this.activeName = this.$route.query.type
    } else {
      setTimeout(() => {
        if(!this.$store.getters.permissionsName('周训练计划')) {
          this.activeName = '1'
        } else if(!this.$store.getters.permissionsName('阶段训练计划')) {
          this.activeName = '2'
        }
        this.tabClick()
      }, 200)
    }
  },
  methods: {
    tabClick() {
      if(this.activeName == '0') {
        let time = setInterval(() => {
          if(!this.$store.getters.permissionsName('周训练计划')) {
            this.activeName = '1'
            clearInterval(time)
            this.create()
          } else if(!this.$store.getters.permissionsName('阶段训练计划')) {
            this.activeName = '2'
            clearInterval(time)
            this.create()
          }
        }, 200)
      } else {
        this.create()
      }
    },
    create() {
      if (this.activeName == '1') {
        this.$router.push({path: '/trainingPlan/weekly/teamPage', query: {type: 1}})
      } else if(this.activeName == '2') {
        this.$router.push({path: '/trainingPlan/stage/stagePage', query: {type: 2}})
      }  
    }
  }
}
</script>

<style lang="scss" scoped>
  /deep/ .text_table{
    background: #fff;
    border-radius: 5px;
    .el-tabs__header{
      margin-bottom: 0;  
      .el-tabs__nav-wrap::after{
        background: #fff;
        margin-left: 10px;
        width: 90%
      }
      .el-tabs__nav{
        height: 55px;
        line-height: 55px;
        margin-left: 50px;
        font-size: 14px;
      }
      .el-tabs__item{
        font-size: 16px;
        // padding: 0 25px;
        &:hover{
          color: #0055E9;
        }
      }
      .is-active{
        color: #0055E9;
      }
      .el-tabs__active-bar{
        background-color: #0055E9;
      }
    }
  }

  .child-top .operation-button .del_btn:hover {
    background: #eb1e44;
    color: #ffffff;
  }
</style>